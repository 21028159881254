import React from "react";
import style from "../modules/AboutCompany.module.css";
import { Carousel, Container } from "react-bootstrap";
import pic2 from "../assests/cc1.jpg";
import pic1 from "../assests/cc2.jpg";
import pic3 from "../assests/cc3.jpg";

const AboutCompany = () => {
  const {
    aboutCompany,
    carouselImgs,
    aboutContent,
    commit,
    aboutBottom,
  } = style;

  return (
    <>
      <section className={`${aboutCompany} pb-3`}>
        <div className="container">
          <h2
            className="p-5"
            style={{ color: "rgba(255, 200, 1, 0.995)", textAlign: "center" }}
          >
            About <span style={{ color: "white" }}>Company</span>{" "}
          </h2>
        </div>

        <div className="container">
          <Container className="d-flex justify-content-center align-items-center tv-screen">
            <div className="container ">
       
                <div className=" container ms-5 ms-md-0 ms-sm-0">
                  <Carousel className={`${style.carouse}`} controls={false} style={{height: '60vh', width:'60vw'}}>
                    <Carousel.Item>
                      <img
                        className={`${carouselImgs} d-block`}
                        src={pic1}
                        alt="First slide"
                      />
                    </Carousel.Item>

                    <Carousel.Item>
                      <img
                        className={`${carouselImgs} d-block`}
                        src={pic2}
                        alt="Second slide"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className={`${carouselImgs} d-block`}
                        src={pic3}
                        alt="Third slide"
                      />
                    </Carousel.Item>
                  </Carousel>
                </div>
       
            </div>
          </Container>
        </div>

        {/* <div className='about-box'>
                <div className='box-gray'></div>
            </div> */}

        <div className={`${aboutBottom} container`} style={{textAlign:'left'}}>ABOUT</div>

        <div className={`${aboutContent}`}>
          <div>
            <p className={`${commit}`}>
              Company <b>History</b>
            </p>
            <p className={`${style.commits}`}>
              
                <b>
                With over 15 years of dedicated service,Dzign Art has built a solid reputation for excellence in industry. Since our inception, we have proudly served over 300 satisfied customers, 
                delivering tailored solutions that exceed expectations. 
                </b>
          
            </p>
            <p className={`${style.commits}`}>
            Our dedication to quality is ingrained in our processes, 
            ensuring that each endeavor reflects our high standards for craftsmanship and client satisfaction.
            </p>

          </div>
        </div>
      </section>
    </>
  );
};

export default AboutCompany;
