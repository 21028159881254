import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import style from '../modules/AllService.module.css';
import {allServiceData} from './AllServiceData';

const AllService = () => {
  return (
    <>
      <div className='m-5'>
          <h2 style={{ color: "rgba(255, 200, 1, 0.995)", textAlign:"center" }}>
            <b> ALL <span style={{ color: "black" }}>SERVICE</span> </b>
          </h2>

          <Container>
          <Row className='mt-4'>

            { allServiceData.map((data) => (
                    <Col md={6} lg={4} >
                    <div className={`${style.allservice} p-4 mt-4`}>
                        <div key={data.id}>
                            <img className={`${style.allImg}`} src={data.image} alt='building' />
                            <h5 className={`my-4`} ><b className={`${style.serviceHead}`}>{data.heading}</b></h5>
                            <p>{data.content}</p>
                        </div>
                    </div>
            </Col>
                )
            )}

          </Row>
          </Container>
        </div>

    </>
  )
}

export default AllService
