import React from "react";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import pic1 from "../assests/interior1.jpg";
import pic2 from "../assests/interior2.jpg";
import pic3 from "../assests/interior3.jpg";
import style from "../modules/AboutOurValue.module.css";

const AboutOurValue = () => {
  return (
    <>
      <section className="mt-3 mb-5">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={4}>
              <div className={`${style.aboutvalue}`}>
                <div className={`${style.carouselBg}`}></div>
                <div className={` ps-5 pb-5 `}>
                  <Carousel controls={false}>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={pic1}
                        alt="First slide"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={pic2}
                        alt="Second slide"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={pic3}
                        alt="Third slide"
                      />
                    </Carousel.Item>
                  </Carousel>
                </div>
              </div>
            </Col>

            <Col sm={12} md={12} lg={8}>
              <div className="m-5">
                <div>
                  <h4
                    className={`${style.aboutYears} ps-4`}
                    style={{ color: "rgb(255, 157, 0)" }}
                  >
                    <span className={`${style.abouthead}`}> 15 </span>
                    <p
                      className="mb-4"
                      style={{ color: "black", fontFamily: "Segoe UI" }}
                    >
                      <b>YEARS OF EXPERIENCE</b>
                    </p>
                  </h4>
                </div>

                <div>
                  <h3>
                    <b>
                      <span
                        className={`${style.aboutContent}`}
                        style={{ color: "black" }}
                      >
                        {" "}
                        Enhancing life quality through a comprehensive, cohesive strategy{" "}
                      </span>{" "}
                    </b>
                  </h3>
                </div>

                <p className="mt-4">
                Commitment to delivering excellence in every project, ensuring high standards of craftsmanship and durability
                </p>

                <div className={`${style.sign} mt-5`}>
                  
                  <p>
                    <span>
                      <b>Stew Jones</b>
                    </span>
                    <br></br>
                    <span>Founder</span>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AboutOurValue;
