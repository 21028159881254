import React from "react";
import About from "../component/About";
import ServiceProject from "../component/ServiceProject";
import ServiceHero from "../component/ServiceHero";
import AllService from "../component/AllService";
import ServiceYears from "../component/ServiceYears";
import ServiceFaq from "../component/ServiceFaq";

const Service = () => {
  return (
    <>
      <ServiceHero />
      <div className="p-5 pb-2" style={{ backgroundColor: "black" }}>
        <About />
      </div>
      <AllService />
      <ServiceYears />
      <ServiceProject />
      <ServiceFaq />
    </>
  );
};

export default Service;
