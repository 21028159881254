import React from 'react'
import style from '../modules/ServiceYears.module.css'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
const ServiceYears = () => {
  return (
    <>
      <section className={`${style.yearsContent} my-5`}>
        <Container>
            <Row className='py-5'>

                <Col md={12} lg={7} >

                    <div className={`${style.serviceBg} p-4`}>
                        <Row>
                            <Col md={6}>
                                <div className={`${style.serviceBg1} p-4`}>
                                    <h5><b>Brochure</b></h5>

                                    <p className='mt-3'>Discover tailored packages for residential, commercial, or industrial projects.
                                         Each ensures quality, 
                                        efficiency, and value, bringing your vision to life.</p>

                                    <button className={`${style.onlineform} p-2 px-3`}>DOWNLOAD BROCHURE</button>
                                </div>
                            </Col>

                            <Col md={6}>
                                <div className='pt-2'>
                                    <h5><b>Let's help you!</b></h5>

                                    <p>We offer comprehensive building solutions for your land, from initial design to final construction. Our team ensures quality, efficiency, and attention
                                         to detail across residential, commercial, or industrial projects. From foundations to finishing touches,
                                         we provide a seamless experience, delivering a structure that meets and exceeds your exact specifications.</p>
                                         <Link to="/contact">
                                    <button className= {`${style.submit}`} >CONTACT</button>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>

                

                <Col md={12} lg={5} className=' mt-sm-4 mt-md-0 mt-lg-0'>
                <div>
                    <h2>
                    <p>We have <span style={{color:"rgb(255, 191, 0)", fontSize: "34px", fontWeight:"bold"}}> 15 years </span> <b>experience in construction</b></p>
                    </h2>

                   {/* <p className='mt-4'><b>Building</b></p>
                    <div class={`${style.border1}`}></div>

                    <p className='mt-4'><b>Interior</b></p>
                    <div class={`${style.border2}`}></div>

                    <p className='mt-4'><b>commercial</b></p>
                    <div class={`${style.border3}`}></div>

                    <p className='mt-4'><b>construction</b></p>
                    <div class={`${style.border4}`}></div> */}
                    <p className='mt-4'><b>Building</b></p>
                    <div class={`${style.border1}`}></div>

                    <p className='mt-4'><b>Interior</b></p>
                    <div class={`${style.border2}`}></div>

                    <p className='mt-4'><b>commercial</b></p>
                    <div class={`${style.border3}`}></div>

                    <p className='mt-4'><b>construction</b></p>
                    <div class={`${style.border4}`}></div>

                  
                </div>
                </Col>
            </Row>
        </Container>
       </section>
    </>
  )
}

export default ServiceYears
