import "./App.css";
import React from "react";
import "./App.css";
import Navigationgallery from "./component/Navigationgallery";
import Images from "./component/Images";
import { Route, Routes } from "react-router-dom";
import Home from './screen/Home'
import AboutUs from "./screen/AboutUs";
import Service from "./screen/Service";
import Contact from "./component/Contact";
import BottomForm from "./component/BottomForm";
import Footer from "./component/Footer"
function App() {
  return (
    <>
      <Navigationgallery />
      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/service" element={<Service />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/gallery" element={<Images />} />
      </Routes>

    <BottomForm />
    <Footer/>
    </>
  );
}
export default App;
