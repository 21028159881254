import React from 'react';
import AboutCompany from '../component/AboutCompany';
import OurServices from '../component/OurServices';
import AboutOurValue from '../component/AboutOurValue';

const AboutUs = () => {
  return (
    <>
      <AboutCompany />
      <AboutOurValue />
      <OurServices />
    </>
  )
}

export default AboutUs
