import w1 from '../assests/roomsandhall.png'
import w2 from '../assests/renovation.png'
import w3 from '../assests/constructions.png'
import w4 from '../assests/interiors.png'
import w5 from '../assests/opinion.png'
import w6 from '../assests/accurate.png'
import w7 from '../assests/structural.png'
import w8 from '../assests/electricity.png'
import w9 from '../assests/homeconstruction.png'

export const allServiceData = [
    {
        id: 1,
        image: w1,
        heading: 'ROOMS & HALLS',
        content: "Our rooms and halls provide exceptional comfort and functionality, tailored to your unique needs."
    },

    {
        id: 2,
        image: w2,
        heading: 'RENOVATION',
        content: "Our renovation services transform spaces with quality, tailored to your needs, on time, and within budget."
    },

    {
        id: 3,
        image: w3,
        heading: 'CONSTRUCTION',
        content: "Top-quality construction services ensuring projects completed on time, within budget, and to specifications."
    },

    {
        id: 4,
        image: w4,
        heading: 'INTERIOR',
        content: "Transforming spaces with innovative designs and meticulous attention to detail, tailored to your unique style."
    },

    {
        id: 5,
        image: w5,
        heading: 'PROFESSIONAL OPINION',
        content: "Expert insights and advice tailored to your needs, ensuring informed decisions and successful outcomes."
    },

    {
        id: 6,
        image: w6,
        heading: 'ACCURATE ENGINEERING',
        content: "Precision engineering solutions ensuring reliability, efficiency, and excellence in every project and application."
    },

    {
        id: 7,
        image: w7,
        heading: 'STRUCTURAL ENGINEERING',
        content: "Advanced engineering solutions ensuring safety, efficiency, and durability in every architectural and infrastructure project."
    },

    {
        id: 8,
        image: w8,
        heading: 'ELECTRICITY',
        content: "Reliable electrical services for commercial, and industrial needs, ensuring safety, efficiency, with comprehensive solutions."
    },

    {
        id: 9,
        image: w9,
        heading: 'HOME CONSTRUCTION',
        content: "Expert home construction delivering quality, efficiency, and customization tailored to your specific needs."
    }
]