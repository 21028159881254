import React, { useState } from 'react';
import style from '../modules/Headergallery.module.css';
import g1 from "../assests/maingallery.jpg";
const Headergallery = ({ setFilterId }) => {
  const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = (id) => {
    setFilterId(id);
    setActiveButton(id); // Update the active button state
  };
  
  return (
    <>
     <div  className={`${style.mainimg}`}>
     <img src={g1} alt="SingleImage" style={{ width: "100%", height: "10%", marginBottom: " 50px" }} />
      
      <div className={`${style.para}`}>
        <p  style={{textAlign:'center'}}>GALLERY</p>
      </div>
     </div>
      
      <div className={`${style.head}`}>
        <div className={`${style.filter_li}`}>
          <button className={activeButton === null ? `${style.active}` : `${style.button} `} onClick={() => handleButtonClick(null)}>All</button>
          <button className={activeButton === 101 ? `${style.active}` : `${style.button}` } onClick={() => handleButtonClick(101)}>Vila</button>
          <button className={activeButton === 102 ? `${style.active}` : `${style.button} ` } onClick={() => handleButtonClick(102)}>Commercial</button>
          <button className={activeButton === 103 ? `${style.active}` : `${style.button} ` } onClick={() => handleButtonClick(103)}>Interior</button>
        </div>
      </div>
    </>
  );
}

export default Headergallery;
