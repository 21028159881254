import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import style from '../modules/ServiceProject.module.css'
import gallery1 from '../assests/project1.png.png'
import gallery2 from '../assests/project2.png.png'
import gallery3 from '../assests/project3.png.png'
import gallery4 from '../assests/project4.png.png'
import { Link } from 'react-router-dom'

const ServiceProject = () => {
  return (
    <>
      <div className={`${style.gallerymt} mt-5`}>
      <div className='container mb-5'>
          <h2 style={{ color: "rgba(255, 200, 1, 0.995)" }}>
            <b> OUR <span style={{ color: "black" }}>PROJECT</span> </b>
          </h2>
        </div>

        <Container>
          <Row>
            <Col  sm={6} md={6} lg={3} className={`${style.testimrg}`}>
                <div>
                  <img className={`${style.galryimage}`} src={gallery1} alt='gallery1'/>
                </div>
            </Col>

            <Col sm={6} md={6} lg={3} className=''>
                <div>
                  <img className={`${style.galryimage}`} src={gallery2} alt='gallery1'/>
                </div>
            </Col>

            <Col  sm={6} md={6} lg={3}  className='mt-sm-4 mt-lg-0'>
                <div>
                  <img className={`${style.galryimage}`} src={gallery3} alt='gallery1'/>
                </div>
            </Col>
            
            <Col  sm={6} md={6} lg={3}  className='mt-sm-4 mt-lg-0'>
              <div>
                  <img className={`${style.galryimage}`} src={gallery4} alt='gallery1'/>
                </div>
               
            </Col>
          </Row>
              <div className={`${style.btnmore}`}>
                <Link to={'/gallery'} className={`${style.more}`}>More</Link>
              </div>
        </Container>
    </div>
    </>
  )
}

export default ServiceProject
