import React, { useState } from "react";
import '../modules/Contact.css';
import emailjs from '@emailjs/browser';

function Inputfield() {
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        console.log(`${name}: ${value}`);
    };

    const sendEmail = (e) => {
        e.preventDefault();
        const templateParams = {
            from_name: formData.username,
            from_email: formData.email,
            message: formData.message
        };
        emailjs.send('service_t6n2tbn', 'template_k5vaegm', templateParams, 'oHogt6zJtiSqpmmJT')
            .then((result) => {
                console.log('Email successfully sent!', result.text);
             
                setFormData({ username: '', email: '', message: '' });
            }, (error) => {
                console.log('Failed to send email:', error.text);
            });
    };

    return (
        <>
            <form onSubmit={sendEmail}>
                <input 
                    name="username" 
                    className="formcontrol mb-4 me-5" 
                    placeholder="Name" 
                    value={formData.username} 
                    onChange={handleChange} 
                />
                <input 
                    name="email" 
                    type="email" 
                    className="formcontrol mb-4 me-5" 
                    required 
                    placeholder="Email" 
                    value={formData.email} 
                    onChange={handleChange} 
                />
                <textarea 
                    name="message" 
                    rows="4" 
                    className="formcontrol mb-4 me-5" 
                    required 
                    placeholder="Message" 
                    value={formData.message} 
                    onChange={handleChange} 
                />
                <button name="submit" type="submit" className="site-button btn-effect">
                    Submit
                </button>
            </form>
        </>
    );
}

export default Inputfield;
