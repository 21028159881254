import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import style from '../modules/OurValue.module.css'

const OurValue = () => {
  return (
    <>
      <section className='mt-5'>
        <Container>
            <Row>
                <Col sm={6} md={4}>
                    <h2 className='mb-4' style={{ color: "rgba(255, 200, 1, 0.995)"}}>
                    <b> OUR <span style={{ color: "black" }}>VALUE</span> </b>
                    </h2>

                    <p>
                    Commitment to delivering excellence in every project, ensuring high standards of craftsmanship and durability
                    </p>

                    <div className={`${style.sign} mt-5`}>
                        <p>
                            <span><b>Stew Jones</b></span><br></br>
                            <span>Founder</span>
                        </p>
                    </div>
                </Col>

                <Col sm={6} md={3}>
                    <Row>
                        <Col md={12} className='p-2'>
                            <div className={`${style.count}`}>
                                <p className={`${style.number}`} >450+</p>
                                <p className={`${style.valueText}`}>projects completed</p>
                            </div>
                        </Col>

                        <Col md={12} className='p-2'>
                            <div className={`${style.count}`}>
                                <p className={`${style.number}`} >800+</p>
                                <p className={`${style.valueText}`}>Active Employees</p>
                            </div>
                        </Col>
                            
                        <Col md={12} className='p-2'>
                            <div className={`${style.count}`}>
                                <p className={`${style.number}`} >420+</p>
                                <p className={`${style.valueText}`}>Happy customers</p>
                            </div>
                        </Col>
                    </Row>
                </Col>

                <Col sm={12} md={5} className='mt-sm-4 mt-md-0'>
                <div>
                    <h2>
                    <p>We have <span style={{color:"rgb(255, 191, 0)", fontSize: "34px", fontWeight:"bold"}}> 15 years </span> <b>experience in construction</b></p>
                    </h2>

                    <p className='mt-4'><b>Building</b></p>
                    <div class={`${style.border1}`}></div>

                    <p className='mt-4'><b>Interior</b></p>
                    <div class={`${style.border2}`}></div>

                    <p className='mt-4'><b>commercial</b></p>
                    <div class={`${style.border3}`}></div>

                    <p className='mt-4'><b>construction</b></p>
                    <div class={`${style.border4}`}></div>

                    {/* <p className='mt-4'><b>Commercial</b></p>
                    <div class={`${style.border5}`}></div> */}
                </div>
                </Col>
            </Row>
        </Container>
       
        </section>
    </>
  )
}

export default OurValue
