import React from "react";
import BackgroundImg from '../component/BackgroundImg';
import Service from "../component/Service";
import About from "../component/About";
import AboutCompany from "../component/AboutCompany";
import OurMission from "../component/OurMission";
import OurServices from "../component/OurServices";
import Parallex from "../component/Parallex";
import OurProject from "../component/OurProject";
import Testimonials from "../component/Testimonials";
import OurValue from '../component/OurValue'

const Main = () => {
  return (
    <>
      <BackgroundImg />
      <Service />
      <About />
      <AboutCompany />
      <OurMission />
      {/* <Parallex /> */}
      <OurServices />
      <OurProject />
      {/* <Testimonials /> */}
      <OurValue />
    </>
  );
};

export default Main;
