import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faPinterest, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import style from '../modules/Footer.module.css';
import { faEnvelope,faBaseball} from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  return (
    <>
      <div className={`${style.container}`}>
        <div className="container">
        <div className='row'>
          <div className='col-lg-6'>
            <div className={`${style.colum1}`} >
              <h1>Dzign Art</h1>
              <p>
              "Innovative solutions for every project"
              </p>
              {/* <a href="instagram"  className={`${style.tag}`}><FontAwesomeIcon icon={faInstagram} className={`${style.smallicon}`} /></a>
              <a href="pinetrest"  className={`${style.tag}`}><FontAwesomeIcon icon={faPinterest} className={`${style.smallicon}`} /></a>
              <a href="linkedin"  className={`${style.tag}`}><FontAwesomeIcon icon={faLinkedin} className={`${style.smallicon}`}/></a>
              <a href="twitter"  className={`${style.tag}`}><FontAwesomeIcon icon={faTwitter} className={`${style.smallicon}`} /></a> */}
            </div>
          </div>
          <div className={`${style.bigboxx} col-lg-6 `}>
          <p>
              Developed by Stew&Jones Technologies Pvt Ltd
              </p>
            {/* <div className={`${style.box}`}>
              <FontAwesomeIcon icon={faEnvelope} className={`${style.envelopeIcon}`} />
             <div  className={`${style.spans}`}>
             <p>contact@example.com</p>
             </div>
            </div> */}
             {/* <div className={`${style.boxs}`}>
             
              <FontAwesomeIcon icon={ faBaseball}  className={`${style.envelopeIcon}`} />
             <div  className={`${style.spans}`}>
             <p>dripple.com/example</p>
             </div>
            </div> */}
          </div>
        </div>
        </div>
       
      </div>
    </>
  );
};

export default Footer;
