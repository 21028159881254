import React from 'react'
import style from '../modules/BackgroundImg.module.css'
import { Carousel} from 'react-bootstrap';
import bg1 from '../assests/home.png.png'
import bg2 from '../assests/home1.png.png'
import bg3 from '../assests/mainint.jpg'

const BackgroundImg = () => {

  return (
    <>
     <div className= {`${style.carousels}`} >
     <Carousel controls={false}>
      <Carousel.Item>
        <img
          className= {`${style.carouselImg} d-block`}
          src={bg1}
          alt="First slide"
        />
        <div className={`${style.box}`}>
          <div className= {`${style.boxShake}`} ><p></p></div>
        </div>

        <div className={`${style.carouselContent}`} data-aos="zoom-in">
          <p>WE MAKE VILLAS</p>
        </div>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className={`${style.carouselImg} d-block`}
          src={bg2}
          alt="Second slide"
        />
        <div className={`${style.box}`}>
          <div className={`${style.boxShake}`}><p></p></div>
        </div>

        <div className={`${style.carouselContent}`}  data-aos="zoom-in">
          <p>COMMERCIAL BUILDINGS</p>
        </div>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className={`${style.carouselImg} d-block`}
          src={bg3}
          alt="Third slide"
        />
        <div className={`${style.box}`}>
          <div className={`${style.boxShake}`} ><p></p></div>
        </div>

        <div className={`${style.carouselContent}`} data-aos="zoom-in">
          <p>INTERIOR WORKS</p>
        </div>
      </Carousel.Item>
      </Carousel>

     </div>
    </>
  )
}

export default BackgroundImg
