import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'; 
import Inputfield from './Inputfield';
// import { Link } from 'react-router-dom';
import '../modules/Contact.css';

const Contact = () => {
  return (
    <>
      <div className='container cc'>
      <div className='row'>
        <div className='col-lg-12'>
        <div className='row'>

<div className='col-lg-6 col-xm-12 col1  '>
   <span className='spann'> CONTACT US</span>
 <Inputfield/>
</div>
<div className='col-lg-6 col2'>
  <div className='c2 ms-3' style={{marginBottom:'5px'}}>
    <FontAwesomeIcon icon={faPhone} className='font' />
    <h6 className='h ms-3'>Call us</h6>
    <a href='tel:+919087000123' className='contact'>(+91) 9087 000123</a>
  </div>

  <div className='c2'>
    <h6 className='h'> <a href='tel:+917548813957' className='contact' style={{marginLeft:'2.6rem'}}>(+91) 75488 13957</a></h6>
   
  </div>

  <div className='c2 ms-3'>
    <FontAwesomeIcon icon={faEnvelope} className='font'/>
    <h6 className='h ms-3'>Mail us</h6>
    <a href="mailto:vijayroot6@gmail.com" className='contact'>info@dizgnart.com</a>
  </div>
  <div className='c2 ms-3'>
    <FontAwesomeIcon icon={faMapMarkerAlt} className='font'/>
    <h6 className='h ms-3'>Office</h6>
  
    <a href="" className='contact'>Marthandam</a>
  </div>
</div>

</div>

        </div>
    </div>
   
      </div>
      <iframe className='maps'
              title="Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15791.820420697935!2d77.21963280000001!3d8.3072605!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b045516f27e1797%3A0x9eaf4bffb1fa19c1!2sMarthandam%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1718270773809!5m2!1sen!2sin"
              width="91%"
             
              height="590px"
              style={{ border: 0 }}
             
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
      
    </>
  );
}

export default Contact;
