import React,{ useState } from 'react'
import Gallery from '../component/Gallery';
import Headergallery from '../component/Headergallery';
import img1 from "../assests/in14.png.png";
import img2 from "../assests/in1.png.png";
import img3 from "../assests/in2.png.png";
import img4 from "../assests/in3.png.png";
import img5 from "../assests/in4.png.png";
import img6 from "../assests/in5.png.png";
import img7 from "../assests/in6.png.png";
import img8 from "../assests/in7.png.png";
import img9 from "../assests/in8.png.png";
import img10 from "../assests/in9.png.png";
import img11 from "../assests/in10.png.png";
import img12 from "../assests/in11.png.png";
import img13 from "../assests/in12.png.png";
import img14 from "../assests/in13.png.png";
import img15 from "../assests/card1.png";
import img16 from "../assests/card2.png";
import img17 from "../assests/card3.png";
import img18 from "../assests/card4.png";
import img19 from "../assests/in16.png.png";
import img20 from "../assests/project1.png.png";
import img21 from "../assests/project2.png.png";
import img22 from "../assests/project3.png.png";
import img23 from "../assests/project4.png.png";
import img24 from "../assests/mission.png";
import img25 from "../assests/vila1.png.png";
import img26 from "../assests/vila2.png.png";
import img27 from "../assests/c1.png.png";
import img28 from "../assests/vila4.png";
import img29 from "../assests/vila5.png";
export const Images = () => {

    const [filterId, setFilterId] = useState(null);
    const image = [
      { id: 104, imageSrc: img1 },
      
      { id: 104, imageSrc:img3 },
      { id: 104, imageSrc: img4},
      { id: 104, imageSrc: img5 },
      { id: 104, imageSrc: img6 },
      { id: 103, imageSrc: img18 },
      { id: 104, imageSrc: img7 },
      { id: 104, imageSrc: img8 },
      { id: 104, imageSrc: img9 },
      { id: 103, imageSrc:img16},
      { id: 103, imageSrc:img27},
      { id: 104, imageSrc: img10 },
      { id: 104, imageSrc: img11},
      { id: 104, imageSrc:img12},
      { id: 104, imageSrc: img13},
      { id: 104, imageSrc: img14 },
      { id: 103, imageSrc: img15},
      
      { id: 103, imageSrc: img17},
     
      { id: 103, imageSrc: img19},
      { id: 103, imageSrc:img20},
      { id: 103, imageSrc: img21},
    
     
      { id: 103, imageSrc:img23},
      { id: 103, imageSrc: img22 },
      { id: 104, imageSrc: img2 },
      { id: 101, imageSrc: img24},
      { id: 101, imageSrc: img25 },
      { id: 101, imageSrc:img26},
      { id: 101, imageSrc: img29},
      { id: 101, imageSrc: img28},
    ];
  
    // const item = [
    //   { name: "Johnny", desc: "what is the hkjkjooiioi" },
    //   { name: "Johnny", desc: "what is the hkjkjooiioi" },
    //   { name: "Johnny", desc: "what is the hkjkjooiioi" },
    //   { name: "Johnny", desc: "what is the hkjkjooiioi" },
    //   { name: "Johnny", desc: "what is the hkjkjooiioi" },
    //   { name: "Johnny", desc: "what is the hkjkjooiioi" },
    //   { name: "Johnny", desc: "what is the hkjkjooiioi" },
    // ];
    let filteredImages = image;
    if (filterId === 102) {
        filteredImages = image.filter(img => [102, 103].includes(img.id));
      } else if (filterId === 103) {
        filteredImages = image.filter(img => [104, 105].includes(img.id));
      } else if (filterId) {
        filteredImages = image.filter(img => img.id === filterId);
      }
    
  return (
    <div>
         <Headergallery setFilterId={setFilterId} />
         <Gallery state={filteredImages}  />
    </div>
  )
}

export default Images;
