import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import serviceHero from '../assests/service1.png'
import style from '../modules/ServiceHero.module.css'

const ServiceHero = () => {
  return (
    <>
      <div classname='service'>
        <Container>
            <Row className='mt-5'>
                <Col md={4} lg={4}>
                    <div>
                        <img className={`${style.heroimg}`} src={serviceHero} alt='ServiceHero' />
                    </div>
                </Col>

                <Col md={8} lg={8}>
                    <div className='pb-5 ps-lg-5 ps-xxl-0'>
                        <div className='pb-5'>
                        <h2><b> We take pride in our work, delivering excellence and ensuring top-tier quality</b> </h2>

                        <p className='mt-4'>
                        At Dzign Art, we pride ourselves on delivering services
                         that are budget-friendly without compromising on quality. 
                         Our experienced team ensures that every project is executed with precision, 
                         adhering to the highest standards of craftsmanship.
                          We also prioritize effective time management, completing projects on schedule to meet your deadlines. 
                          Trust us to provide reliable, high-quality solutions that are tailored 
                          to fit your budget and timeline.
                        </p>

                        <Row className='px-3 mt-4'>
                            <Col md={4} lg={4} className={`${style.serviceCol1}`}>
                                <div className={`${style.serviceCall} pb-2 pt-3`}>
                                <p>Call For a Quote: </p>
                                <a href='tel:+919087000123' className={`${style.calling}`} style={{fontSize:"20px"}}><b>(+91) 9087 000123</b></a>

                                </div>
                            </Col>
                            
                            <Col md={8} lg={8} className={`${style.serviceCol2}`}>
                                <div className={`${style.servicebutton} mt-3`}>
                                   <Link to="/contact">
                                <button className={`${style.onlineform} p-1 px-3`}>ONLINE ESTIMATE FORM</button>
                                </Link>
                                </div>
                            </Col>
                        </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
      </div>
    </>
  )
}

export default ServiceHero
