import React, { useState } from 'react';
import '../modules/Navigationgallery.css'
import logo from "../assests/logo.png";
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';

const Navigationgallery = () => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <>
        <div className='index'>
        <div className='container d-flex align-items-center justify-content-between'>
          <p className='nav1 my-2 me-auto'>
            <a href="mailto:vijayroot6@gmail.com"className='custom-link'>
              <FontAwesomeIcon icon={faEnvelope} className='me-2 icons'/>info@dzignart.com
            </a>
            <a href="tel:+919087000123"className='custom-link'>
              <FontAwesomeIcon icon={faPhone} className='me-2 icons'/>(+91) 9087 000123
            </a>
            <a href="tel:+917548813957"className='custom-link'>
              <FontAwesomeIcon icon={faPhone} className='me-2 icons'/>(+91) 75488 13957
            </a>
          </p>
          <p className='nav1 my-2 ms-auto'>
            <button onClick={handleShow} className='buttonapp'>Make An Appointment</button>
          </p>
        </div>
      </div>
      <div className='navbartop'>
      <div className='bg-white navbar-custom '>
        <Navbar collapseOnSelect expand="lg" bg="white" className='px-3 container'>
          <Navbar.Brand href="/" style={{width:'75%'}}><img src={logo} alt="logo" className="logo-img"></img></Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className='ms-auto'>
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/service">Services</Nav.Link>
              <Nav.Link href="/about">About</Nav.Link>
              <Nav.Link href="/gallery">Gallery</Nav.Link>
              <Nav.Link href="/contact">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
       <div className='arrow'>
       <FontAwesomeIcon icon={faAngleLeft} className='me-2 arrowanimation' onClick={handleShow}/>
       </div>
      </div>
      </div>
     {/* popupbox */}
      <Modal show={showModal} onHide={handleClose} ClassName="modal">
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body className='popupbox'>
          
          <div className='modal-right'>
          <div>
  <FontAwesomeIcon icon={faPhone} />
  <p>
    <a href="tel:+919087000123" style={{ textDecoration: 'none', color: 'black' }}>
      (+91) 9087 000123 </a> </p>
      <p> <a href="tel:+917548813957" style={{ textDecoration: 'none', color: 'black' }}>
      (+91) 75488 13957 </a> </p>
      </div>
            <div>
              <FontAwesomeIcon icon={faEnvelope}  />
              {/* <h6>Mail us</h6> */}
             <p ><a href="mailto:vijayroot6@gmail.com" style={{ textDecoration: 'none', color: 'black' }}>info@dizgnart.com</a></p> 
            </div>
            <div>
              <FontAwesomeIcon icon={faMapMarkerAlt}  />
              {/* <h6>Office</h6> */}
              <p>Marthandam</p>
            </div>
          </div>
        </Modal.Body>
        
      </Modal>
   
    </>
  );
};

export default Navigationgallery;
