import React from "react";
import style from '../modules/OurServices.module.css';
import { Card, Col, Row } from "react-bootstrap";
import bulidingIcon from '../assests/architecture.png'
import bulidingIcon1 from '../assests/commercial.png'
import bulidingIcon2 from '../assests/interiorwork.png'
import bulidingIcon3 from '../assests/government.png'

const OurServices = () => {

  return (
    <>
      <section className={`${style.ourservicebottom} my-5`}>
        <div className="mb-5">
        <div  style={{position:"relative"} }>
            <div className={`${style.services}`}>
            <h2 style={{ color: "rgba(255, 200, 1, 0.995)" }}>
            <b> OUR <span style={{ color: "white" }}>SERVICES</span> </b>
            </h2>
            <p className={`${style.servicesContent} mt-3`}>At Dzign Art , we offer a comprehensive range of construction services tailored to meet the unique needs of every project</p>
            </div>

            <Row className={`${style.servicesrow} mx-5`}>
                <Col sm={6} md={6} lg={3} className={`${style.Cards}`}>
                <div className={style.border}></div>
                    <Card className={`${style.card} px-4 pb-4`} >
               
                    <div className={`${style.servicesflex}`}>
                    <Card.Img variant="top" className={`${style.image}`} style={{width: "70px", height: '70px'}} src={bulidingIcon} />
                    <p className={`${style.number}`}>1</p>
                    </div>
                    <Card.Title><b>Villas</b></Card.Title>
                    <Card.Text className="mt-2" style={{textAlign: "justify"}}>
                    Explore unparalleled luxury in our custom-designed villas, meticulously crafted to reflect your style.
                        </Card.Text>
                    </Card>
                </Col>

                <Col sm={6} md={6} lg={3} className={`${style.Cards} mt-md-0`}>
                <div className={style.border}></div>

                    <Card className={`${style.card} px-4 pb-4`}>
                    <div className={`${style.servicesflex}`}>
                    <Card.Img variant="top" className={`${style.image}`} style={{width: "70px", height: '70px'}} src={bulidingIcon1} />
                    <p className={`${style.number}`}>2</p>
                    </div>
                    <Card.Title><b>Commercial Buildings</b></Card.Title>
                    <Card.Text className="mt-2" style={{textAlign: "justify"}}>
                    Creating commercial buildings with enhanced productivity, 
                    and functionality through innovative design.
                        </Card.Text>
                    </Card>

                </Col>

                <Col sm={6} md={6} lg={3} className={`${style.Cards} mt-sm-3  mt-md-3 mt-lg-0`}>
                <div className={style.border}></div>

                    <Card className={`${style.card} px-4 pb-4`}>
                    <div className={`${style.servicesflex}`}>
                    <Card.Img variant="top" className={`${style.image}`} style={{width: "70px", height: '70px'}} src={bulidingIcon2} />
                    <p className={`${style.number}`}>3</p>
                    </div>
                    <Card.Title><b>Interior Works</b></Card.Title>
                    <Card.Text className={`${style.cardContent} mt-2`} style={{textAlign: "justify"}}>
                    Creating exceptional interior through innovative designs that blend functionality,and personalized style.
                        </Card.Text>
                    </Card>

                </Col>

                <Col sm={6} md={6} lg={3} className={`${style.Cards} mt-sm-3 mt-md-3 mt-lg-0`}>
                <div className={style.border}></div>

                    <Card className={`${style.card} px-4 pb-4`}>
                    <div className={`${style.servicesflex}`}>
                    <Card.Img variant="top" className={`${style.image}`} style={{width: "70px", height: '70px'}} src={bulidingIcon3} />
                    <p className={`${style.number}`}>4</p>
                    </div>
                    <Card.Title><b>Goverment project</b></Card.Title>
                    <Card.Text className="mt-2" style={{textAlign: "justify"}}>
                    Enhance public infrastructure with compliant, sustainable, and innovative government projects.

                        </Card.Text>
                    </Card>

                </Col>
            </Row>
       </div>

        </div>
       
      </section>
      <section className={`${style.servicesBottom}`}> </section>
    </>
  );
};

export default OurServices;
