import React, { useState } from 'react';
import style from '../modules/OurMission.module.css';
import { Col, Container, NavLink, Row } from 'react-bootstrap';
import missionimg from '../assests/vv1.png';
import emailjs from '@emailjs/browser';

const OurMission = () => {
  const [formData, setFormData] = useState({
    username: '',
    phone: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: formData.username,
      from_email: formData.email,
      from_phone: formData.phone,
      message: formData.message
    };

    emailjs.send(
      'service_t6n2tbn', 
      'template_k5vaegm', 
      templateParams,
      'oHogt6zJtiSqpmmJT' 
    ).then((result) => {
      console.log('Email successfully sent!', result.text);
      setFormData({
        username: '',
        phone: '',
        email: '',
        message: ''
      });
    }).catch((error) => {
      console.log('Failed to send email:', error.text);
    });
  };

  const symbol = '>';

  return (
    <>
      <section className={`${style.mission} pb-3`}>
        <h2 className='p-5' style={{ color: 'rgba(255, 200, 1, 0.995)', textAlign: 'center' }}>
          <b>Our <span style={{ color: 'black' }}>Mission</span></b>
        </h2>
        <Container>
          <Row className='mb-5'>
            <Col sm={12} md={6} lg={4} className={`${style.missioncol} p-3`}>
              <div className={`${style.missionColumn} p-4`}>
                <span style={{fontSize: "25px"}}>Site Areas</span><br />
                <span style={{fontSize: "35px"}}><b>What We Serve</b></span>
                <p className='mt-4 mb-5'>
                  Our capabilities include infrastructure projects that support urban development and public services. We specialize in constructing roads, bridges, water treatment facilities, and other essential infrastructure.
                </p>
                <div style={{display: "flex"}}>
                  <p className='me-3'>{symbol}</p>
                  <NavLink to="#" className={style.linknav}><b>Design and Build Solutions</b></NavLink>
                </div>
                <div style={{display: "flex"}}>
                  <p className='me-3'>{symbol}</p>
                  <NavLink to="#" className={style.linknav}><b>Project Planning</b></NavLink>
                </div>
                <div style={{display: "flex"}}>
                  <p className='me-3'>{symbol}</p>
                  <NavLink to="#" className={style.linknav}><b>Site Analysis</b></NavLink>
                </div>
                <div style={{display: "flex"}}>
                  <p className='me-3'>{symbol}</p>
                  <NavLink to="#" className={style.linknav}><b>Budget and Cost Estimation</b></NavLink>
                </div>
                <div style={{display: "flex"}}>
                  <p className='me-3'>{symbol}</p>
                  <NavLink to="#" className={style.linknav}><b>LEED Consultation</b></NavLink>
                </div>
              </div>
            </Col>

            <Col sm={12} md={6} lg={4} className='p-3'>
              <img className={`${style.image}`} src={missionimg} alt='mission-building' />
            </Col>

            <Col sm={12} md={12} lg={4} className='p-3'>
            <div style={{ color: 'white', backgroundColor: 'black',width: '100%',
                      height: '100%' }} className="p-4">    
               <h4 style={{fontSize: '35px'}}>Get In <b>Touch</b></h4>
                <form onSubmit={sendEmail}>
                  <input
                    type="text"
                    name="username"
                    placeholder='Name'
                    className={`${style.forminput} mt-3`}
                    value={formData.username}
                    onChange={handleChange}
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder='Email'
                    className={`${style.forminput} mt-4`}
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    name="phone"
                    placeholder='Phone'
                    className={`${style.forminput} mt-4`}
                    value={formData.phone}
                    onChange={handleChange}
                  />
                    <textarea  name="message"  value={formData.message}  onChange={handleChange} placeholder='Message' id="textarea" class={`${style.forminput} mt-4`}/>
                  <button className={`${style.submit} mt-5`} type="submit">SUBMIT</button>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default OurMission;
