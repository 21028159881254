import React from "react";
import style from "../modules/About.module.css";
import { Link } from 'react-router-dom';
import bg1 from "../assests/vila1.jpg";
import bg2 from "../assests/vila2.jpg";
import bg3 from "../assests/vila3.jpg";
import { Col, Container, Row } from "react-bootstrap";

const About = () => {
  return (
    <>
     <section className={`${style.ourService}`}>
      <Container>
      <Row className="mb-5">
        <Col sm={6} md={6} lg={4} xl={3} className={`${style.servicesImg}`}>
          <div className= {`${style.imageContainer}`} >
            <div className= {`${style.content}`} >
              <p className="ps-3">
                <p></p>Our Expertise
                <p>Design Excellence</p>
              </p>
              <p className={`${style.plusSymbol}`}>+</p>
            </div>

            <img src={bg1} alt="our-about" className={`${style.aboutImage}`} />
          </div>
          </Col>

        <Col sm={6} md={6} lg={4} xl={3} className={`${style.servicesImg}`}>
        <div className={`${style.imageContainer}`}>
          <div className={`${style.content}`}>
            <p className="ps-3">
              <p>Project Timeline</p>
              <p>Innovative Design</p>
            </p>
            <p className={`${style.plusSymbol}`}>+</p>
          </div>

          <img src={bg2} alt="our-about" className={`${style.aboutImage}`} />
        </div>
        </Col>

        <Col sm={6}  md={6} lg={4}  xl={3} className= {`${style.servicesImg} mt-sm-4 mt-md-4 m-lg-0`} >
        <div className={`${style.imageContainer}`}>
          <div className={`${style.content}`}>
          <p className="ps-3">
              <p>Time Efficiency
              </p>
              <p>Aesthetic Innovation</p>
            </p>
            <p className={`${style.plusSymbol}`}>+</p>
          </div>
          <div>
          <img src={bg3} alt="our-about" className={`${style.aboutImage}`} />
          </div>     
        </div>
        </Col>

        <Col sm={6} md={6} lg={12}  xl={3} className= {`${style.servicesImg} mt-sm-4 mt-md-4 mt-xl-0`}>
        <div className= {`${style.building}`}>
          <div className= {`${style.buildingContent}  p-3 p-md-4`}>
            <h4 className="mt-2">Cost Control</h4>
            <h6 className="mt-1">Construction Excellence</h6>

            <p className="mt-3">Creating captivating designs that elevate spaces with creativity and precision, setting new standards.</p>

            <Link to="/service">
            <button className=  {`${style.viewAll}  mt-2 mb-2`} >VIEW ALL</button>
              </Link>
           
          </div>
        </div>
        </Col>
     </Row>
      </Container>
    
     </section>
    </>
  );
};

export default About;
