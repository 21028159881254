import React, { useState } from 'react';
import style from '../modules/BottomForm.module.css';
import { Container } from 'react-bootstrap';
import emailjs from '@emailjs/browser';

const BottomForm = () => {
  const [formData, setFormData] = useState({
    username: '',
    phone: '',
    email: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: formData.username,
      from_phone:formData.phone,
            from_email: formData.email,
           
    };

    emailjs.send(
      'service_t6n2tbn', 
      'template_k5vaegm', 
      templateParams ,
      'oHogt6zJtiSqpmmJT' 
    ).then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      setFormData({
        username: '',
        phone: '',
        email: ''
      });
    }).catch((error) => {
      console.error('FAILED...', error);
    });
  };

  return (
    <>
      <section className={`${style.form} mt-5`}>
        <Container>
          <h2 className={`${style.formHeading} pt-5 pb-2`}>Quick Callback</h2>
          <form className={`${style.formContainer} pb-5`} onSubmit={handleSubmit}>
            <input 
              className={`${style.emailInput}`} 
              type='text' 
              name='username' 
              placeholder='Name' 
              value={formData.username}
              onChange={handleChange}
            />
            <input 
              className={`${style.emailInput}`} 
              type='text' 
              name='phone' 
              placeholder='Phone Number' 
              value={formData.phone}
              onChange={handleChange}
            />
            <input 
              className={`${style.emailInput}`} 
              type='email' 
              name='email' 
              placeholder='EmailID' 
              value={formData.email}
              onChange={handleChange}
            />
            <div>
              <button className={`${style.more}`} type="submit">Submit</button>
            </div>
          </form>
        </Container>
      </section>
    </>
  );
};

export default BottomForm;
