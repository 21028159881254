import React from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import style from "../modules/ServiceFaq.module.css";
import w3 from "../assests/assistant.png";
import w4 from "../assests/finance.png";

const ServiceFaq = () => {
  return (
    <>
      <section  className="mb-5">
        <Container>
          <Row>
            <Col md={12} lg={6} className="mt-3">
                <div className="mb-4">
                    <h2  style={{ color: "rgba(255, 200, 1, 0.995)" }}    >
                        <b>
                        SOME <span style={{ color: "black" }}>FAQ</span>
                        </b>
                    </h2>
                </div>

              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>How long does it take to build?</Accordion.Header>
                  <Accordion.Body>
                  According to our customers, the process is efficient and well-managed. On average,
                   our projects take a minimum of 6 months to complete,
                   ensuring quality and attention to detail every step of the way.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>What payment methods do you accept?</Accordion.Header>
                  <Accordion.Body>
                  We accept various payment methods as recommended by our clients, including credit/debit cards, 
                  bank transfers, and financing options.
                   Our goal is to provide flexible and convenient payment solutions to meet your needs.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header> Do you provide any warranty?</Accordion.Header>
                  <Accordion.Body>
                  Yes, we provide a 5-year warranty on all our projects, ensuring peace of mind and long-term 
                  satisfaction with our work. This warranty covers any structural issues or defects that may arise, 
                  reflecting our commitment to quality and reliability.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>

            <Col md={12} lg={6} className="mt-3">
                <div className="mb-4">
                    <h2 style={{ color: "rgba(255, 200, 1, 0.995)" }}  >
                        <b> OUR <span style={{ color: "black" }}>SOLUTION</span> </b>
                    </h2>
                </div>
                <Row>
                  <Col md={6} >
                    <div className={`${style.allservice} p-4`}>
                      <div>
                        <img
                          className={`${style.allImg}`}
                          src={w3}
                          alt="building"
                        />
                        <h5 className="my-4">
                          <b>ASSISTANCE</b>
                        </h5>
                        <p>
                        We use top-quality materials to ensure durability, & safety
                        </p>
                      </div>
                    </div>
                  </Col>

                  <Col md={6} >
                    <div className={`${style.allservice1} p-4`}>
                      <div>
                        <img
                          className={`${style.allImg1}`}
                          src={w4}
                          alt="building"
                        />
                        <h5 className="my-4">
                          <b>FINANCING</b>
                        </h5>
                        <p>
                        We assist with securing bank loans to help you finance your project
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
   
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ServiceFaq;
