import React, { useEffect } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import bg1 from '../assests/card1.png'
import bg2 from '../assests/card2.png'
import bg3 from '../assests/card3.png'
import bg4 from '../assests/card4.png'
import style from '../modules/Service.module.css'
import AOS from 'aos';
import 'aos/dist/aos.css';

const Service = () => {
  
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);

  return (
    <>
        <div className='container mb-5' data-aos="fade-up"
     data-aos-duration="1000">  
       <h2 className={`${style.service} my-5`}>SERVICES</h2>

       <Row>

       <Col xs={12} sm={6} md={6} lg={3} className={`${style.cardImgZoom}` }>
        <Card className=''>
          <Card.Img variant="top" src={bg1} />
          <Card.Body>
            <Card.Title>Medical Facility Design
         </Card.Title>
            <Card.Text>
            Tailoring hospital designs to meet each provider's unique needs, ensuring optimal functionality.
            </Card.Text>
            <Link to="/gallery">
            <Button className=" border-dark btn-sm btn-outline-dark" variant="btn btn-outline-dark">More</Button>
              </Link>
          </Card.Body>
        </Card>
      </Col>

      <Col  xs={12} sm={6} md={6}  lg={3} className= {`${style.cardImgZoom} mt-md-0`} >
        <Card className='' >
          <Card.Img variant="top" src={bg2} />
          <Card.Body>
            <Card.Title> Modern Layouts</Card.Title>
            <Card.Text>
            Designing sleek, attractive store layouts  to enhance shopping experiences and maximize space. 
            </Card.Text>
            <Link to="/gallery">
            <Button className=" border-dark btn-sm btn-outline-dark" variant="btn btn-outline-dark">More</Button>
              </Link>
          </Card.Body>
        </Card>
      </Col>

      <Col  xs={12} sm={6} md={6} lg={3} className= {`${style.cardImgZoom} mt-sm-3 mt-md-3 mt-lg-0`} >
        <Card className=''>
          <Card.Img variant="top" src={bg3} />
          <Card.Body>
            <Card.Title> Public Infrastructure</Card.Title>
            <Card.Text>
            Creating safe, enduring public buildings such as schools and government facilities, emphasizing long-term value.
            </Card.Text>
            <Link to="/gallery">
            <Button className=" border-dark btn-sm btn-outline-dark" variant="btn btn-outline-dark">More</Button>
              </Link>
          </Card.Body>
        </Card>
      </Col>

      <Col  xs={12} sm={6} md={6} lg={3} className= {`${style.cardImgZoom} mt-sm-3 mt-md-3 mt-lg-0`}>
        <Card className=''>
          <Card.Img variant="top" src={bg4} />
          <Card.Body>
            <Card.Title> Industrial Building</Card.Title>
            <Card.Text>
            Building strong manufacturing and industrial facilities with a focus on excellence for sustained productivity.
            </Card.Text>
            <Link to="/gallery">
            <Button className=" border-dark btn-sm btn-outline-dark" variant="btn btn-outline-dark">More</Button>
              </Link>
          </Card.Body>
        </Card>
      </Col>
      </Row>
    </div>
    </>
  )
}

export default Service