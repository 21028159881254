import React from 'react';
import '../modules/Gallery.css';

const ChildComponent = ({ state }) => {
  return (
    <div className='gallery'>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
        {state.map((person, index) => (
          <div key={index} className="image-container">
            <img src={person.imageSrc} alt='cons' className="image" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChildComponent;
